import styled from '@emotion/styled';
import { ScrollArea, Text } from '~/shared/components';

export const StyledScrollArea = styled(ScrollArea)(() => ({
    flex: 1,
}));

export const Title = styled(Text)(({ theme }) => ({
    marginBottom: theme.spaces[7],
}));

export const StyledDrawerFooter = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[4],
    flexWrap: 'wrap',
    width: '100%',
}));

export const ClearContainer = styled.div({
    flexShrink: 1,
    flexGrow: 0,
});

export const SubmitContainer = styled.div({
    flexShrink: 0,
    flexGrow: 1,
    '> *': {
        width: '100%',
    },
});
