import styled from '@emotion/styled';

export const StyledAccordionItem = styled.div(({ theme }) => ({
    width: '100%',
    borderTop: `1px solid ${theme.colors.grey20}`,
    borderBottom: `1px solid ${theme.colors.grey20}`,
    display: 'block',
    listStyle: 'none',

    '& + &': {
        borderTop: 'none',
    },
}));

export const StyledAccordionHeader = styled.div<{ disabled?: boolean }>(({ theme, disabled }) => ({
    display: 'grid',
    gap: theme.spaces[4],
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    padding: `${theme.spaces[7]} 0`,
    cursor: !disabled ? 'pointer' : '',
    opacity: disabled ? 0.4 : 1,
    transition: `opacity ${theme.animations.timingShort}`,
}));

export const StyledAccordionContent = styled.div<{ isOpen: boolean; innerHeight: number | string }>(
    ({ isOpen, innerHeight }) => ({
        height: isOpen ? innerHeight : 0,
        overflow: 'hidden',
        transition: 'height 0.2s ease',
    }),
);

export const StyledAccordionContentInner = styled.div<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    paddingBottom: theme.spaces[4],
    opacity: isOpen ? 1 : 0,
    transition: 'opacity 0.2s ease',
}));

export const StyledDefaultIcon = styled.div(({ theme }) => ({
    fontSize: '1.2em',
    path: {
        strokeWidth: '2px',
        color: theme.colors.red,
    },
}));
