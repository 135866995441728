import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { StyledInputField } from '~/shared/components/FormElements/components/InputField';
import { InputField } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { FILTER_DEBOUNCE } from '../utils';

const StyledFieldGrid = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[6],
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    marginBottom: theme.spaces[1],
    [`${StyledInputField}`]: {
        width: '100%',
    },
}));

type DimensionFieldsProps = {
    onInputChange: ({
        SafetyZoneAreaLength,
        SafetyZoneAreaWidth,
    }: {
        SafetyZoneAreaWidth?: string;
        SafetyZoneAreaLength?: string;
    }) => void;
    market: string;
    isInvalid?: boolean;
    initialValues: {
        widthValue: string;
        lengthValue: string;
    };
};

function DimensionFields({
    onInputChange,
    market,
    initialValues,
    isInvalid = false,
}: DimensionFieldsProps) {
    const [width, setWidth] = useState(initialValues.widthValue);
    const [length, setLength] = useState(initialValues.lengthValue);

    let debounce: NodeJS.Timeout;

    useEffect(() => {
        setWidth(initialValues.widthValue);
        setLength(initialValues.lengthValue);
    }, [initialValues]);

    const { translate } = useTranslation();

    const appendLabel = market === 'US' ? 'ft.' : 'cm';

    const handleInputChange = (field: 'width' | 'length', value: string) => {

        clearTimeout(debounce);
        
        field === 'width' ? setWidth(value) : setLength(value);

        if(width === '' || length === '' || width === '0' || length === '0' || value === '') return null;

        const newWidth = field === 'width' ? value : width;
        const newLength = field === 'length' ? value : length;

        debounce = setTimeout(() => {
            onInputChange({
                SafetyZoneAreaWidth: newWidth,
                SafetyZoneAreaLength: newLength,
            });
        }, FILTER_DEBOUNCE.DEBOUNCE);


        
    };

    return (
        <StyledFieldGrid>
            <div>
                <InputField
                    isInvalid={isInvalid}
                    invalidMessage={
                        (isInvalid &&
                            translate('Kompan.Commerce.FiltersSafetyZoneAreaInputErrorMessage')) ||
                        ''
                    }
                    append={appendLabel}
                    label={translate('Kompan.Commerce.FiltersSafetyZoneAreaLabelWidth')}
                    inputMode={'numeric'}
                    min={'0'}
                    value={width}
                    onChange={(e) => handleInputChange('width', e.target.value)}
                />
            </div>
            <div>
                <InputField
                    isInvalid={isInvalid}
                    append={appendLabel}
                    label={translate('Kompan.Commerce.FiltersSafetyZoneAreaLabelLength')}
                    inputMode={'numeric'}
                    min={'0'}
                    value={length}
                    onChange={(e) => handleInputChange('length', e.target.value)}
                />
            </div>
        </StyledFieldGrid>
    );
}

export default DimensionFields;
