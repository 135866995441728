import styled from '@emotion/styled';
import { Text, Accordion, Icon } from '~/shared/components';
import { breakpoints } from '~/theme';
import { ifProp } from 'styled-tools';
import { shouldNotForwardProps } from '~/shared/utils/styled';

export const StyledFiltersHeader = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
});

export const AccordionItemLabel = styled.label<{ disabled?: boolean }>(
    ({ theme, disabled = false }) => ({
        display: 'grid',
        gap: theme.spaces[4],
        gridTemplateColumns: '1fr min-content',
        alignItems: 'center',
        padding: `${theme.spaces[7]} 0`,
        cursor: !disabled ? 'pointer' : '',
        borderBottom: `1px solid ${theme.colors.grey20}`,
        opacity: disabled ? 0.4 : 1,
        transitionProperty: 'opacity',
        transitionDuration: theme.animations.timingShort,
    }),
);

export const AccordionItemTitle = styled(Text)({
    width: '100%',
    height: 20,
    display: 'flex',
    alignItems: 'center',
});

export const StyledAccordionItemTooltip = styled(Icon)(({ theme }) => ({
    marginLeft: theme.spaces[2],
    marginRight: 'auto',
}));

export const StyledFiltersHeaderIcon = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingRight: theme.spaces[3],
    height: 0,
    overflow: 'visible',
    ['svg']: {
        width: '30px',
        height: '30px',
    },
}));

export const StyledFilterAccordionContent = styled.div<{ columns?: number }>(
    ({ theme }) => ({
        display: 'grid',
        width: '100%',
        gap: theme.spaces[5],
        paddingLeft: theme.spaces[1],
    }),
    ifProp({ columns: 2 }, () => ({
        gridTemplateColumns: `1fr 1fr`,
    })),
);

export const StyledAccordion = styled(Accordion, {
    shouldForwardProp: shouldNotForwardProps(['isChild']),
})<{ isChild: boolean }>(
    () => ({
        display: 'none',
        [breakpoints.sm]: {
            display: 'block',
        },
    }),
    ifProp('isChild', () => ({
        display: 'block',
    })),
);

export const Circle = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontSize: 12,
    lineHeight: '32px',
    borderRadius: '50%',
    width: 32,
    height: 32,
    textAlign: 'center',
    marginTop: '-5px',
    marginBottom: '-5px',
    marginLeft: 'auto',
}));
