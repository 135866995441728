type GenericEventBusCallback = (payload?: unknown) => void;
type TypedEventBusCallback<T> = (payload: T) => void;

type EventBusQueue = {
    [key: string]: GenericEventBusCallback[];
};

class _EventBus {
    private bus: EventBusQueue = {};

    queueCallbackIndex(id: string, callback: GenericEventBusCallback) {
        return this.bus[id]?.findIndex((item) => item == callback);
    }
    queueHas(id: string, callback: GenericEventBusCallback) {
        return this.queueCallbackIndex(id, callback) != -1;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    off(id: string, callback: (...params: any[]) => void) {
        while (this.queueHas(id, callback)) {
            const index = this.queueCallbackIndex(id, callback);
            this.bus[id].splice(index, 1);
        }
    }

    on<T>(id: string, callback: TypedEventBusCallback<T>) {
        if (this.bus[id]) {
            this.bus[id].push(callback as GenericEventBusCallback);
        } else {
            this.bus[id] = [callback as GenericEventBusCallback];
        }
        return () => {
            this.off(id, callback);
        };
    }

    emit(id: string, payload?: unknown) {
        if (this.bus[id]) {
            this.bus[id].map((callback) => {
                callback(payload);
            });
        }
    }
}

export const EventBus = new _EventBus();
