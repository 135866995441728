import styled from '@emotion/styled';

export const StyledRangeFilter = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    gap: theme.spaces[2],
    flex: 1,
    flexDirection: 'column',
}));

export const StyledRangeButtonsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    gap: theme.spaces[2],
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const StyledSpacer = styled.div(({ theme }) => ({
    height: '2px',
    minWidth: theme.spaces[2],
    flex: 1,
    backgroundColor: theme.colors.grey60,
}));
