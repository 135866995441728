import React, { useEffect, useRef, useState } from 'react';
import { AccordionContext } from './context/AccordionContext';
import { StyledAccordion } from './styled';
import { EventBus } from '~/shared/utils/event-bus/event-bus';
export type AccordionProps = {
    /**
     * Allows a single or multiple accordions to be open at onces.
     * `single` will automaticlly close other open accordions,
     * when opening a new accordion
     */
    type?: 'single' | 'multiple';

    /**
     * Use AccordionItem as children
     */
    children: React.ReactNode;

    defaultState?: string[];
};

export const Accordion = ({
    children,
    type = 'multiple',
    defaultState,
}: AccordionProps): JSX.Element => {
    const [states, setOpenStates] = useState<string[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const setStates = (stateIds: string[]) => {
        const newStates = stateIds.filter((id, index) => {
            if (type === 'single') {
                return !states.includes(id);
            }

            // remove duplicates
            return stateIds.indexOf(id) === index;
        });

        setOpenStates(newStates);
        return newStates;
    };

    const open = (stateId: string) => {
        setStates([...states, stateId]);
    };

    const close = (stateId: string) => {
        setStates(states.filter((id) => id !== stateId));
    };

    const toggle = (stateId: string) => {
        states.includes(stateId) ? close(stateId) : open(stateId);
    };

    const scrollToAccordionItem = (stateId: string) => {
        if (!containerRef.current) return;
        const targetAccordionItem = containerRef.current.querySelector(`#${stateId}`);
        if (targetAccordionItem) {
            targetAccordionItem.scrollIntoView();
        }
    };

    useEffect(() => {
        const unsubOpen = EventBus.on('AccordionOpen', open);
        const unsubToggle = EventBus.on('AccordionToggle', toggle);
        const unsubClose = EventBus.on('AccordionClose', close);
        const unsubScroll = EventBus.on('AccordionScrollTo', scrollToAccordionItem);
        return () => {
            unsubOpen();
            unsubToggle();
            unsubClose();
            unsubScroll();
        };
    }, []);

    useEffect(() => {
        if (defaultState && defaultState.length > 0) {
            setStates(defaultState);
        }
    }, [defaultState]);

    return (
        <StyledAccordion ref={containerRef}>
            <AccordionContext.Provider value={{ states, setStates, toggle, open, close }}>
                {children}
            </AccordionContext.Provider>
        </StyledAccordion>
    );
};
