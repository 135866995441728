import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    StyledAccordionItem,
    StyledDefaultIcon,
    StyledAccordionHeader,
    StyledAccordionContent,
    StyledAccordionContentInner,
} from './styled';
import ArrowUp from '$icons/arrow-up.svg';
import ArrowDown from '$icons/arrow-down.svg';
import { AccordionContext } from '../../context/AccordionContext';
import { useId } from '@radix-ui/react-id';
import { Text } from '$shared/components';

export type AccordionItemChildrenRenderProps = {
    isOpen: boolean;
    toggle: () => void;
    open: () => void;
    close: () => void;
    id: string;
};

export type AccordionItemProps = {
    /**
     * Text for the headline OR a component that will replace the default header and be used to trigger for the accordion
     */
    header?: string | React.ReactNode;

    /**
     * Controlls if the accordion is open or not
     */
    initAsOpen?: boolean;

    /**
     * Override the unique generated id
     */
    id?: string;
    /**
     * Disable the expand functionality
     */
    disabled?: boolean;
    /**
     * Accordion content
     */
    children?:
        | React.ReactNode
        | React.ReactNode[]
        | ((state: AccordionItemChildrenRenderProps) => React.ReactNode | React.ReactNode[]);
};

export const AccordionItem = ({
    id: initialId,
    header,
    initAsOpen = false,
    disabled = false,
    children,
}: AccordionItemProps): JSX.Element => {
    // Ensures present id. Fallback unique id
    const innerContentRef = useRef<HTMLDivElement>(null);
    const id = useId(initialId);

    const [innerHeightRef, setInnerHeightRef] = useState<number | string>(0);

    const { states, ...context } = useContext(AccordionContext);

    const isOpen = useMemo(() => states.includes(id), [states]);

    const measureInnerContentHeight = useCallback(() => {
        const innerContentElm = innerContentRef?.current;

        if (innerContentElm) {
            const { height } = innerContentElm.getBoundingClientRect();

            setInnerHeightRef(height || 0);
        }
    }, [innerContentRef, states]);

    // Measure inner content height
    useEffect(() => {
        isOpen && measureInnerContentHeight();

        window.addEventListener('resize', measureInnerContentHeight);
        return () => window.removeEventListener('resize', measureInnerContentHeight);
    }, [children, isOpen]);

    useEffect(() => {
        initAsOpen && context.open(id);
    }, []);

    return (
        <StyledAccordionItem id={id}>
            <StyledAccordionHeader
                disabled={disabled}
                onClick={() => {
                    !disabled && context.toggle(id);
                }}
            >
                {typeof header === 'string' ? (
                    <Text variant="display5" children={header} style={{ lineHeight: 1.6 }} />
                ) : (
                    header
                )}
                <StyledDefaultIcon as={isOpen ? ArrowUp : ArrowDown} />
            </StyledAccordionHeader>
            <StyledAccordionContent isOpen={isOpen} innerHeight={innerHeightRef}>
                <StyledAccordionContentInner ref={innerContentRef} isOpen={isOpen}>
                    {children as React.ReactNode}
                </StyledAccordionContentInner>
            </StyledAccordionContent>
        </StyledAccordionItem>
    );
};
