import styled from '@emotion/styled';
import { Button } from '~/shared/components';

export const StyledSelectedFilters = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    gap: theme.spaces[2],
    flex: 1,
}));

export const StyledSelectedFilterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.colors.grey05,
    fontSize: theme.fontSizes.xs,
}));

export const StyledSelectedClearButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.colors.grey05,
    fontSize: theme.fontSizes.xs,
    outline: `1px solid ${theme.colors.grey05}`,
}));
