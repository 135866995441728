import React, { useCallback, useEffect, useState } from 'react';
import { InputField } from '../InputField';
import {
    StyledInput,
    StyledLabel,
    StyledLegend,
    StyledRangeButtonsContainer,
    StyledWrapMultiThumbSlider,
} from './styled';

export type MultiThumbSliderOnChangeProps = {
    minValue: number;
    maxValue: number;
};

export type MultiThumbSliderProps = {
    /**
     * Adds a label to the input field. This is required for accessibility.
     */
    label: string;
    /**
     * Adds a label to the From input field.
     */
    labelRangeFrom?: string;
    /**
     * Adds a label to the To input field.
     */
    labelRangeTo?: string;
    /**
     * Adds a invalidMessage to the To input field.
     */
    invalidMessage?: string;
    /**
     *
     */
    minValue?: number;
    /**
     *
     */
    maxValue?: number;
    /**
     *
     */
    minValueRange?: number;
    /**
     *
     */
    maxValueRange?: number;
    /**
     *
     */
    step?: number;
    /**
     *
     */
    onChange?: ({ minValue, maxValue }: MultiThumbSliderOnChangeProps) => void;
    formattedValue?: (value: string) => string;
    unformattedValue?: (value: string, e: HTMLInputElement) => string;
    /**
     * Prepend a string or a component to the min input field
     */
    prependMin?: React.ReactNode;
    /**
     * Prepend a string or a component to the max input field
     */
    prependMax?: React.ReactNode;
    /**
     * Append a string or a component to the min input field
     */
    appendMin?: React.ReactNode;
    /**
     * Append a string or a component to the max input field
     */
    appendMax?: React.ReactNode;
    inputPattern?: string;
};

function clampValue(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}

function getValue(value: number, step: number) {
    let newValue;

    const rest = value % step;

    if (rest >= step / 2) {
        newValue = value + (step - rest);
    } else {
        newValue = value - rest;
    }

    return newValue;
}

export const MultiThumbSlider = ({
    label,
    labelRangeFrom = 'FROM',
    labelRangeTo = 'TO',
    invalidMessage = 'Please insert a valid number',
    minValue: minValueProp,
    maxValue: maxValueProp,
    minValueRange = 1,
    maxValueRange = 100,
    formattedValue = (value) => value,
    unformattedValue = (value) => value,
    prependMin,
    prependMax,
    appendMin,
    appendMax,
    step = 1,
    inputPattern,
    onChange = () => null,
}: MultiThumbSliderProps) => {
    const [minValue, setMinValue] = useState(minValueProp ?? minValueRange);
    const [maxValue, setMaxValue] = useState(maxValueProp ?? maxValueRange);
    const [maxValueTemp, setMaxValueTemp] = useState<number | undefined>(undefined);
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        if (maxValueProp !== maxValue) maxValueProp && setMaxValue(maxValueProp ?? maxValueRange);
        if (minValueProp !== minValue) setMinValue(minValueProp ?? minValueRange);
    }, [minValueProp, maxValueProp]);

    const plusSignal = Number(maxValue) >= maxValueRange ? `+` : '';

    function handleValueChange(e: React.ChangeEvent<HTMLInputElement>, isMin: boolean) {
        if (isNaN(Number(e.target.value))) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);

        const newValue = Number(unformattedValue(e.target.value, e.target));

        const clampedValue = clampValue(newValue, minValueRange, maxValueRange);

        if (isMin) {
            if (clampedValue > maxValue) return;
            setMinValue(clampedValue);
            onChange({ minValue: clampedValue, maxValue });
        } else {
            if (clampedValue < minValue) {
                setMaxValueTemp(clampedValue);
                return;
            }
            setMaxValueTemp(undefined);
            setMaxValue(clampedValue);
            onChange({ minValue, maxValue: clampedValue });
        }
    }

    function handleRangeValueChange(e: React.ChangeEvent<HTMLInputElement>, isMin: boolean) {
        if (isNaN(Number(e.target.value))) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);

        const newValue = getValue(Number(e.target.value), step);

        const clampedValue = clampValue(newValue, minValueRange, maxValueRange);

        if (isMin) {
            if (clampedValue > maxValue) return;
            setMinValue(clampedValue);
        } else {
            if (clampedValue < minValue) {
                setMaxValueTemp(clampedValue);
                return;
            }
            setMaxValueTemp(undefined);
            setMaxValue(clampedValue);
        }
    }
    function handlerKeyPressMin(e: React.KeyboardEvent<HTMLInputElement>) {
        let newValue = getValue(minValue, step);

        const stepValue = step === 1 ? 0 : step;

        if (e.key === 'ArrowRight')
            newValue = clampValue(
                newValue + stepValue + minValueRange,
                minValueRange,
                maxValueRange,
            );
        if (e.key === 'ArrowLeft')
            newValue = clampValue(newValue - stepValue, minValueRange, maxValueRange);

        if (newValue > maxValue + stepValue) return;

        setMinValue(newValue);
        onChange({
            minValue: newValue,
            maxValue,
        });
    }

    function handlerKeyPressMax(e: React.KeyboardEvent<HTMLInputElement>) {
        let newValue = getValue(maxValue, step);

        const stepValue = step === 1 ? 0 : step;

        if (e.key === 'ArrowRight') {
            newValue = clampValue(
                newValue + stepValue + minValueRange,
                minValueRange,
                maxValueRange,
            );
        }
        if (e.key === 'ArrowLeft') {
            newValue = clampValue(newValue - stepValue, minValueRange, maxValueRange);
        }

        if (newValue < minValue) return;

        setMaxValue(newValue);
        onChange({
            minValue,
            maxValue: newValue,
        });
    }

    const handleSliderRangeChange = useCallback(() => {
        onChange({ minValue, maxValue });
    }, [minValue, maxValue, onChange]);

    return (
        <fieldset aria-labelledby={label}>
            <StyledLegend id={label}>{label}</StyledLegend>
            <StyledRangeButtonsContainer>
                <div>
                    <InputField
                        isInvalid={isInvalid}
                        invalidMessage={(isInvalid && invalidMessage) || ''}
                        prepend={prependMin}
                        append={appendMin}
                        label={labelRangeFrom}
                        inputMode={'numeric'}
                        pattern={inputPattern}
                        value={formattedValue(minValue.toString())}
                        onChange={(e) => handleValueChange(e, true)}
                    />
                </div>
                <div>
                    <InputField
                        isInvalid={isInvalid}
                        prepend={prependMax ? plusSignal + prependMax : plusSignal}
                        append={appendMax}
                        label={labelRangeTo}
                        inputMode={'numeric'}
                        pattern={inputPattern}
                        min={maxValueRange}
                        value={formattedValue(
                            maxValueTemp ? maxValueTemp.toString() : maxValue.toString(),
                        )}
                        onChange={(e) => handleValueChange(e, false)}
                    />
                </div>
            </StyledRangeButtonsContainer>

            <StyledWrapMultiThumbSlider
                minValue={minValue}
                maxValue={maxValue}
                minValueRange={minValueRange}
                maxValueRange={maxValueRange}
            >
                <StyledLabel htmlFor="min">minimum value for {label}</StyledLabel>
                <StyledInput
                    id="min"
                    onKeyDown={handlerKeyPressMin}
                    onChange={(e) => handleRangeValueChange(e, true)}
                    onMouseUp={handleSliderRangeChange}
                    onTouchEnd={handleSliderRangeChange}
                    type="range"
                    min={minValueRange}
                    value={minValue}
                    max={maxValueRange}
                />
                <StyledLabel htmlFor="max">maximum value for {label}</StyledLabel>
                <StyledInput
                    id="max"
                    onKeyDown={handlerKeyPressMax}
                    onChange={(e) => handleRangeValueChange(e, false)}
                    onMouseUp={handleSliderRangeChange}
                    onTouchEnd={handleSliderRangeChange}
                    type="range"
                    min={minValueRange}
                    value={maxValue}
                    max={maxValueRange}
                />
            </StyledWrapMultiThumbSlider>
        </fieldset>
    );
};
