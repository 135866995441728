import styled from '@emotion/styled';
import { Button, Text } from '~/shared/components';
import { ifProp } from 'styled-tools';
import { breakpointsIsolated } from '~/theme';

export const StyledButton = styled(Button)({
    textDecoration: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    [breakpointsIsolated.sm]: {
        minHeight: '3rem',
    },
    [breakpointsIsolated.xs]: {
        minHeight: '3rem',
    },
});

export const StyledCategoryText = styled(Text)<{ active: boolean; unavailable?: boolean }>(
    ({ theme }) => ({
        color: theme.colors.black,
    }),
    ifProp('unavailable', ({ theme }) => ({
        color: theme.colors.grey20,
        pointerEvents: 'none',
    })),
    ifProp('active', ({ theme }) => ({
        color: theme.colors.red,
        pointerEvents: 'all',
    }))
);
