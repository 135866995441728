import styled from '@emotion/styled';

export const StyledRangeButtonsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    width: '100%',
    gap: theme.spaces[4],
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spaces[6],
    ' > *': {
        maxWidth: '40%'
    },

    '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '2px',
        width: theme.spaces[2],
        flex: 1,
        backgroundColor: theme.colors.grey60,
    },
}));

type StyledWrapMultiThumbSliderProps = {
    minValue: number;
    maxValue: number;
    minValueRange: number;
    maxValueRange: number;
};

export const StyledWrapMultiThumbSlider = styled.div<StyledWrapMultiThumbSliderProps>(
    ({ theme, minValue, maxValue, minValueRange, maxValueRange }) => ({
        position: 'relative',
        display: 'grid',
        // overflow: 'hidden',
        margin: '0 12px',
        padding: `${theme.spaces[4]} 0px ${theme.spaces[4]}`,
        width: 'calc(100% - 24px)',

        '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '0',
            content: '""',
            height: theme.spaces[1],
        },

        '&:before': {
            width: 'calc(100% + 24px)',
            left: '-12px',
            backgroundColor: theme.colors.grey20,
            borderRadius: theme.borderRadius.xs,
        },

        '&:after': {
            width:
                'calc(' +
                ((maxValue - minValue) / (maxValueRange - minValueRange)) * 100 +
                '% + 24px)',
            left:
                'calc(' +
                ((minValue - minValueRange) / (maxValueRange - minValueRange)) * 100 +
                '% - 12px)',
            backgroundColor: theme.colors.primary,
            borderRadius: theme.borderRadius.xs,
        },
    })
);

export const StyledLegend = styled.div({
    position: 'absolute',
    clipPath: 'inset(50%)',
});

export const StyledLabel = styled.label({
    position: 'absolute',
    clipPath: 'inset(50%)',
});

export const StyledInput = styled.input<{ id: string }>(({ theme, id }) => ({
    gridColumn: '1',
    gridRow: '2',
    margin: '0',
    background: 'none',
    color: theme.colors.white,
    font: 'inherit',
    pointerEvents: 'none',
    WebkitAppearance: 'none',
    zIndex: '1',

    '&::-webkit-slider-runnable-track, &::-webkit-slider-thumb': {
        WebkitAppearance: 'none',
    },

    '&::-webkit-slider-runnable-track, &::-moz-range-track': {
        width: '100%',
        height: '100%',
        background: 'none',
    },

    '&::-webkit-slider-thumb': {
        pointerEvents: 'auto',
        width: theme.spaces[6],
        height: theme.spaces[6],
        border: 'none',
        outline: `1px solid ${theme.colors.grey20}`,
        outlineOffset: '-1px',
        borderRadius: '50%',
        // paddingLeft: '-0.5rem',
        background: 'currentcolor',
        transform: id === 'min' ? 'translateX(-12px)' : 'translateX(12px)',
    },

    '&::-moz-range-thumb': {
        border: 'none',
        width: '1em',
        height: '1em',
        borderRadius: 0,
        background: 'currentcolor',
        pointerEvents: 'auto',
    },

    '&:focus': {
        zIndex: 2,
        outline: '0px currentcolor',
        color: theme.colors.primary,

        '&::-webkit-slider-thumb': {
            outline: '0',
        },
    },
}));
