import { TranslationKey } from '~/lib/data-contract';

export const getFacetOptionTranslation = (
    translate: (key: TranslationKey, fallback?: string) => string,
    attribute: string,
    value: number | string | boolean,
    translationsGroup = 'Kompan.Commerce',
    facetType?: string
) => {
    if (facetType == 'BOOLEAN') {
        return translate(`${translationsGroup}.Filters${attribute}` as TranslationKey, attribute);
    }

    return translate(
        `${translationsGroup}.Filters${attribute}Value` as TranslationKey,
        `${value}`
    ).replace('[x]', `${value}`);
};
