import React, { useEffect, useRef, useState } from 'react';
import { Button, InputField } from '~/shared/components';
import { DropdownItem } from '~/shared/components/MultipleSelector/model';
import { useProductsStore } from '../../hooks';
import { Facet, FacetRange } from '../../../model';

import { StyledRangeFilter, StyledRangeButtonsContainer, StyledSpacer } from './styled';

export type RangeFilterProps = {
    facet: Facet;
};

export const RangeFilter = ({ facet }: RangeFilterProps) => {
    const { setSelectOptions, selectedFacets, removeFacetSelection } = useProductsStore();

    const [currentSelection, setCurrentSelection] = useState<{
        min: number | undefined;
        max: number | undefined;
    }>({ min: facet.range?.min, max: facet.range?.max });
    const [currentAppliedRange, setCurrentAppliedRange] = useState<DropdownItem | null>(null);
    const inputMinRef = useRef<HTMLInputElement>(null);
    const inputMaxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const facetIndex = selectedFacets.findIndex(
            (selectedFacet) => selectedFacet.attribute === facet.attribute
        );
        if (facetIndex > -1 && selectedFacets[facetIndex].items.length) {
            setCurrentAppliedRange(selectedFacets[facetIndex].items[0]);
        }
    }, []);

    useEffect(() => {
        const facetIndex = selectedFacets.findIndex(
            (selectedFacet) => selectedFacet.attribute === facet.attribute
        );
        if (facetIndex > -1 && selectedFacets[facetIndex].items.length) {
            setCurrentSelection({
                min: (selectedFacets[facetIndex].items[0].value as { min?: number; max?: number })
                    .min,
                max: (selectedFacets[facetIndex].items[0].value as { min?: number; max?: number })
                    .max,
            });
            setCurrentAppliedRange(selectedFacets[facetIndex].items[0]);
        } else {
            setCurrentSelection({
                min: facet.range?.min,
                max: facet.range?.max,
            });
        }
    }, [selectedFacets]);

    const applyRange = () => {
        setSelectOptions(facet.attribute, [
            {
                value: {
                    min: inputMinRef.current?.value,
                    max: inputMaxRef.current?.value,
                } as { min?: number; max?: number },
                label: `[${inputMinRef.current?.value} - ${inputMaxRef.current?.value}]`,
            },
        ]);
    };

    const resetRange = () => {
        setCurrentSelection({ min: facet.range?.min, max: facet.range?.max });
        removeFacetSelection(facet.attribute);
    };

    return (
        <StyledRangeFilter>
            <StyledRangeButtonsContainer>
                <InputField
                    ref={inputMinRef}
                    label={'min'} // TODO: Add translation for range filter
                    step={1}
                    min={facet.range?.min}
                    value={(currentSelection.min || 0).toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentSelection({
                            ...currentSelection,
                            min: parseInt(event.target.value),
                        })
                    }
                />
                <StyledSpacer />
                <InputField
                    ref={inputMaxRef}
                    label={'max'} // TODO: Add translation for range filter
                    step={1}
                    min={facet.range?.max}
                    value={(currentSelection.max || 100).toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentSelection({
                            ...currentSelection,
                            max: parseInt(event.target.value),
                        })
                    }
                />
            </StyledRangeButtonsContainer>
            <StyledRangeButtonsContainer>
                <Button
                    disabled={
                        currentSelection.min == facet.range?.min &&
                        currentSelection.max == facet.range?.max
                    }
                    onClick={resetRange}
                >
                    {
                        // TODO: Add translation for range filter
                        'Reset'
                    }
                </Button>
                <Button
                    disabled={
                        currentSelection.min == (currentAppliedRange?.value as FacetRange)?.min &&
                        currentSelection.max == (currentAppliedRange?.value as FacetRange)?.max
                    }
                    onClick={applyRange}
                >
                    {
                        // TODO: Add translation for range filter
                        'Apply'
                    }
                </Button>
            </StyledRangeButtonsContainer>
        </StyledRangeFilter>
    );
};
