import React, { useEffect, useState } from 'react';
import { InputField } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';

type AreaFieldProps = {
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    market: string;
    initialValue?: string;
    isInvalid?: boolean;
};

function AreaField({
    onInputChange,
    market,
    initialValue = '',
    isInvalid = false,
}: AreaFieldProps) {
    const label = market === 'US' ? 'SQ FT.' : 'm2';

    const [value, setValue] = useState(initialValue);
    const { translate } = useTranslation();

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        setValue(e.target.value);
        onInputChange(e);
    };

    return (
        <InputField
            isInvalid={isInvalid}
            invalidMessage={
                (isInvalid &&
                    translate('Kompan.Commerce.FiltersSafetyZoneAreaInputErrorMessage')) ||
                ''
            }
            label={label}
            inputMode={'numeric'}
            value={value}
            onChange={handleOnChange}
        />
    );
}

export default AreaField;
