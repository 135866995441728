import React, { useEffect, useRef, useState } from 'react';
import DimensionFields from './DimensionFields';
import AreaField from './AreaField';
import { formatAsSQFt, formatAsSQM, useMarket } from '~/shared/utils';
import styled from '@emotion/styled';
import { Button, Text } from '~/shared/components';
import { useTheme } from '@emotion/react';
import { Facet, SelectedFacet } from '../../../model';
import { DropdownItem } from '~/shared/components/MultipleSelector/model';
import { FILTER_DEBOUNCE } from '../utils';
import { useTranslation } from '~/shared/utils/translation';
import { RelewiseRangesType } from '../../requests';

type SpacesProps = {
    facet: Facet;
    selectedItems?: SelectedFacet[];
    onChange: (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem,
        spaceArea?: {
            SafetyZoneAreaWidth?: string;
            SafetyZoneAreaLength?: string;
        },
    ) => void;
};

const StyledWrapRowFlex = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
}));

enum METRIC {
    DIMENSIONS,
    AREA,
}

function getUpperBoundExclusive(value: unknown): number | undefined {
    if (value && (value as RelewiseRangesType).upperBoundExclusive !== undefined) {
        return (value as RelewiseRangesType).upperBoundExclusive;
    }
    return undefined;
}

function Spaces({ facet, selectedItems, onChange }: SpacesProps) {
    const [metricSystem, setMetricSystem] = useState(METRIC.DIMENSIONS);

    let debounce: NodeJS.Timeout;

    const { market } = useMarket();

    const { colors } = useTheme();

    const [area, setArea] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const { translate } = useTranslation();
    const [AreaFieldInitialValue, setAreaFieldInitialValue] = useState('');
    const [dimensionFieldsInitialValue, setDimensionFieldsInitialValue] = useState({
        widthValue: '',
        lengthValue: '',
    });

    useEffect(() => {
        return () => {
            clearTimeout(debounce);
        };
    }, []);

    const firstRenderRef = useRef(true);

    const handleDimensionFieldsChange = ({
        SafetyZoneAreaLength,
        SafetyZoneAreaWidth,
    }: {
        SafetyZoneAreaWidth?: string;
        SafetyZoneAreaLength?: string;
    }) => {
        clearTimeout(debounce);

        const numericLength = Number(SafetyZoneAreaLength);
        const numericWidth = Number(SafetyZoneAreaWidth);

        if (isNaN(numericLength) || isNaN(numericWidth)) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);

        setArea(Number(SafetyZoneAreaLength) * Number(SafetyZoneAreaWidth));

        debounce = setTimeout(() => {
            onChange(undefined, facet, undefined, {
                SafetyZoneAreaLength,
                SafetyZoneAreaWidth,
            });
        }, FILTER_DEBOUNCE.DEBOUNCE);
    };

    function handleAreaFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        clearTimeout(debounce);

        const value = Number(e.target.value);

        if (isNaN(value)) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);

        debounce = setTimeout(() => {
            onChange(
                e,
                facet,
                {
                    value: {
                        lowerBoundInclusive: 0,
                        upperBoundExclusive: value + 0.01,
                    },
                    label: `${0} - ${value}`,
                },
                {
                    SafetyZoneAreaLength: undefined,
                },
            );
        }, FILTER_DEBOUNCE.DEBOUNCE);
    }

    useEffect(() => {
        if (selectedItems?.[0]?.items[0] && firstRenderRef.current) {
            firstRenderRef.current = false;
            const attribute = selectedItems[0]?.attribute;

            if (attribute?.startsWith('SafetyZoneArea_')) {
                const value = getUpperBoundExclusive(selectedItems[0].items[0]?.value);

                if (value !== undefined) {
                    setAreaFieldInitialValue(`${value - 0.01}`);
                }
            }

            if (attribute?.startsWith('SafetyZoneAreaWidth_')) {
                const widthValue = getUpperBoundExclusive(selectedItems[0].items[0]?.value);
                const lengthValue = getUpperBoundExclusive(selectedItems[0].items[1]?.value);

                if (widthValue !== undefined && lengthValue !== undefined) {
                    setDimensionFieldsInitialValue({
                        widthValue: `${widthValue - 1}`,
                        lengthValue: `${lengthValue - 1}`,
                    });
                }
            }
        } else if (!selectedItems?.[0]?.items[0]) {
            setAreaFieldInitialValue('');
            setDimensionFieldsInitialValue({
                widthValue: '',
                lengthValue: '',
            });
        }
    }, [selectedItems]);

    return (
        <div>
            {metricSystem === METRIC.DIMENSIONS ? (
                <>
                    <DimensionFields
                        onInputChange={handleDimensionFieldsChange}
                        market={market}
                        initialValues={dimensionFieldsInitialValue}
                        isInvalid={isInvalid}
                    />
                    <StyledWrapRowFlex>
                        <Button
                            onClick={() => setMetricSystem(METRIC.AREA)}
                            animationDamping={15}
                            animationSpace={5}
                            animationStiffness={150}
                            shade="dark"
                            size="Small"
                            variant="Plain"
                        >
                            {translate(
                                'Kompan.Commerce.FiltersSafetyZoneAreaSwitchToArea',
                                'Switch to area',
                            )}
                        </Button>
                        <Text variant="body" style={{ color: colors.grey60 }}>
                            {translate('Kompan.Commerce.FiltersSafetyZoneAreaYourAreaIs').replace(
                                '[x]',
                                ``,
                            )}
                            {market === 'US' ? (
                                `${formatAsSQFt(area)} SQ FT.`
                            ) : (
                                <>
                                    {`${formatAsSQM(area)} m`}
                                    <sup
                                        style={{
                                            verticalAlign: 'super',
                                            fontSize: 'smaller',
                                        }}
                                    >
                                        2
                                    </sup>
                                </>
                            )}
                        </Text>
                    </StyledWrapRowFlex>
                </>
            ) : (
                <>
                    <AreaField
                        onInputChange={handleAreaFieldChange}
                        market={market}
                        initialValue={AreaFieldInitialValue}
                        isInvalid={isInvalid}
                    />

                    <StyledWrapRowFlex>
                        <Button
                            onClick={() => setMetricSystem(METRIC.DIMENSIONS)}
                            animationDamping={15}
                            animationSpace={5}
                            animationStiffness={150}
                            shade="dark"
                            size="Small"
                            variant="Plain"
                        >
                            {translate(
                                'Kompan.Commerce.FiltersSafetyZoneAreaSwitchToDimensions',
                                'Switch to dimensions',
                            )}
                        </Button>
                    </StyledWrapRowFlex>
                </>
            )}
        </div>
    );
}

export default Spaces;
