import { ReactNode } from 'react';
import { StyledButton, StyledCategoryText } from './styled';

type ProductCategoryListButtonProps = {
    value: string | number;
    isActive: boolean;
    isDisabled: boolean;
    onClick: (value: string | number) => void;
    children?: ReactNode | ReactNode[];
};

export const ProductCategoryListButton = ({
    value,
    isActive,
    isDisabled,
    onClick,
    children = null,
}: ProductCategoryListButtonProps) => {
    return (
        <StyledButton variant="Plain" onClick={() => onClick(value)}>
            <StyledCategoryText unavailable={isDisabled} variant="display5" active={isActive}>
                {children}
            </StyledCategoryText>
        </StyledButton>
    );
};
