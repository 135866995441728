import React, { useMemo } from 'react';
import { AccordionItem, Checkbox, Tooltip, Text, DropdownItem } from '~/shared/components';
import {
    StyledAccordion,
    AccordionItemLabel,
    AccordionItemTitle,
    StyledFiltersHeader,
    StyledFilterAccordionContent,
    Circle,
    StyledAccordionItemTooltip,
} from './styled';
import { RangeFilter } from '../RangeFilter';
import { useTranslation } from '~/shared/utils/translation';
import { M140ProductListModule, TranslationKey } from '~/lib/data-contract';
import InfoCircle from '$icons/info-circle.svg';
import { getFacetOptionTranslation } from '../../utils';
import { isSameFacetItem } from '../../utils/isSameFacetItem';
import { Facet, SelectedFacet } from '$templates/blocks/components/M140ProductsList';
import { MultiRangeFilter } from '../MultiRangeFilter';
import { RangeItem } from '~/shared/components/MultipleSelector/model';
import Spaces from '../Spaces/Spaces';
import { useQuery } from 'react-query';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { useModule } from '~/templates/blocks/hooks/useModule';
import { days } from '~/shared/utils/time';

export interface AccordionFiltersBarProps {
    facets: Facet[];
    selectedFacets: SelectedFacet[];
    onFacetChange: (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem,
    ) => void;
    isChild?: boolean;
    translationsGroup?: string;
}

type PlayActivitiesImages = {
    ActivityType: string;
    Name: string;
    Description: string;
    Alias: string;
    ImageAssetUrl: string;
    VideoAssetUrl: string | null;
};

export const AccordionFiltersBar = ({
    facets,
    selectedFacets,
    onFacetChange,
    isChild = false,
    translationsGroup = 'Kompan.Commerce',
}: AccordionFiltersBarProps) => {
    const { translate } = useTranslation();
    const { AZ_GET_ACTIVITY_IMAGES } = publicRuntimeConfig();
    const { element } = useModule<M140ProductListModule>();
    const validWebGeneralTags = element?.webGeneralTagsOptions ?? [];
    const titleOverwrites: Record<string, string | undefined> = {
        WebGeneralTags: element?.webGeneralTagsHeadline,
    };
    const getPlayActivitiesImages = async () => {
        const url = `${AZ_GET_ACTIVITY_IMAGES}&locale=en-int&filterActivityType=play`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data: { Value: PlayActivitiesImages[] } = await response.json();
            return data.Value ?? [];
        } catch (error) {
            console.error('Error getting images to playActivities', error);
            return [];
        }
    };

    const { data: playActivityImages } = useQuery<PlayActivitiesImages[]>(
        ['play-activities-images'],
        {
            queryFn: () => getPlayActivitiesImages(),
            cacheTime: days(1),
            staleTime: days(1),
        },
    );

    const getTooltip = (text: string) => {
        if (!text) return;
        return (
            <Tooltip content={text} delayDuration={0} contentWidth={220}>
                <StyledAccordionItemTooltip>
                    <InfoCircle />
                </StyledAccordionItemTooltip>
            </Tooltip>
        );
    };

    const getSelectedFilterCount = (facetLabel: string) => {
        const selected = [...selectedFacets]?.find(
            // startsWith - to match values from SafetyZoneArea
            (selectedFacet) => selectedFacet.attribute.startsWith(facetLabel),
        );
        return selected?.items.length ? selected.items.length : 0;
    };

    const sanitizedFacets = useMemo(() => {
        return facets?.filter((facet) => {
            if (facet.displayType === 'MULTI_CHECKBOX_WEB_GENERAL_TAGS' && 'items' in facet) {
                return facet.items?.some((item) => validWebGeneralTags.includes(`${item.value}`));
            }
            return true;
        });
    }, [facets, validWebGeneralTags]);

    return (
        <StyledAccordion type="single" isChild={isChild}>
            {sanitizedFacets?.map((facet, index) => {
                if (facet.displayType == null) {
                    return null;
                }
                const label = facet?.attribute?.match(/([^_]+)/)?.[1] || '';
                const tooltipText = translate(
                    `${translationsGroup}.Filters${label}Tooltip` as TranslationKey,
                );
                const translationTitle = translate(
                    `${translationsGroup}.Filters${label}` as TranslationKey,
                    label,
                );
                const title =
                    titleOverwrites[`${facet.key ?? facet.attribute}`] ?? translationTitle;

                if (facet.displayType == 'BOOLEAN') {
                    const count = facet.items?.find((x) => x.value == true || x.value == 'True')
                        ?.count;
                    const isSelected = !!selectedFacets.find(
                        (selectedFacet) =>
                            selectedFacet.attribute == facet.attribute &&
                            selectedFacet.items.length,
                    );
                    const isSelectable =
                        facet.items?.find((x) => x.value == true || x.value == 'True') ||
                        isSelected;

                    return (
                        <AccordionItemLabel
                            key={`${facet.attribute}-${index}`}
                            disabled={!isSelectable}
                        >
                            <AccordionItemTitle variant="display5" as="span">
                                <Text variant="display5" as="p">
                                    {title}
                                    {count && ` (${count})`}
                                </Text>
                                {getTooltip(tooltipText)}
                            </AccordionItemTitle>
                            <Checkbox
                                disabled={!isSelectable}
                                key={`${facet.attribute}_checkbox`}
                                checked={isSelected}
                                onChange={(event) => {
                                    onFacetChange(event, facet);
                                }}
                            />
                        </AccordionItemLabel>
                    );
                }

                const selectedItems = selectedFacets.find(
                    (selectedFacet) => selectedFacet.attribute === facet.attribute,
                )?.items;

                const selectCount = getSelectedFilterCount(label);

                return (
                    <AccordionItem
                        id={`FacetAccordion_${facet.attribute}`}
                        disabled={
                            !(
                                facet.items?.length ||
                                facet.displayType === 'MULTI_RANGE' ||
                                facet.displayType === 'SPACE'
                            )
                        }
                        key={`${facet.attribute}-${index}`}
                        initAsOpen={false}
                        header={
                            <StyledFiltersHeader>
                                <AccordionItemTitle variant="display5" as="span">
                                    <Text variant="display5" as="p">
                                        {title}
                                    </Text>
                                    {getTooltip(tooltipText)}
                                    {selectCount > 0 && <Circle>{selectCount}</Circle>}
                                </AccordionItemTitle>
                            </StyledFiltersHeader>
                        }
                    >
                        {facet.displayType === 'MULTI_CHECKBOX_PLAY_ACTIVITIES' && facet.items ? (
                            <StyledFilterAccordionContent columns={2}>
                                {facet?.items.map((item) => (
                                    <Checkbox
                                        key={`${facet.attribute}_${item.label}`}
                                        image={
                                            playActivityImages
                                                ?.find((option) => option.Name === item.label)
                                                ?.ImageAssetUrl.replace(' ', '%20') || ''
                                        }
                                        label={`${getFacetOptionTranslation(
                                            translate,
                                            facet.attribute,
                                            item.label,
                                            translationsGroup,
                                            facet.displayType,
                                        )} (${item.count ?? 0})`}
                                        checked={
                                            selectedItems?.find((option) =>
                                                isSameFacetItem(option, item),
                                            ) !== undefined
                                        }
                                        onChange={(event) => {
                                            onFacetChange(event, facet, item);
                                        }}
                                    />
                                ))}
                            </StyledFilterAccordionContent>
                        ) : null}

                        {facet.displayType === 'SPACE' ? (
                            <StyledFilterAccordionContent>
                                <Spaces
                                    facet={facet}
                                    selectedItems={selectedFacets}
                                    onChange={onFacetChange}
                                />
                            </StyledFilterAccordionContent>
                        ) : null}

                        {facet.displayType === 'MULTI_CHECKBOX' && facet.items ? (
                            <StyledFilterAccordionContent>
                                {facet.items.map((item) => (
                                    <Checkbox
                                        key={`${facet.attribute}_${item.label}`}
                                        label={`${getFacetOptionTranslation(
                                            translate,
                                            facet.attribute,
                                            item.label,
                                            translationsGroup,
                                            facet.displayType,
                                        )} (${item.count ?? 0})`}
                                        checked={
                                            selectedItems?.find((option) =>
                                                isSameFacetItem(option, item),
                                            ) !== undefined
                                        }
                                        onChange={(event) => {
                                            onFacetChange(event, facet, item);
                                        }}
                                    />
                                ))}
                            </StyledFilterAccordionContent>
                        ) : null}

                        {facet.displayType === 'MULTI_CHECKBOX_WEB_GENERAL_TAGS' && facet.items ? (
                            <StyledFilterAccordionContent>
                                {facet.items
                                    .filter((item) => validWebGeneralTags.includes(`${item.value}`))
                                    .map((item) => (
                                        <Checkbox
                                            key={`${facet.attribute}_${item.label}`}
                                            label={`${translate(
                                                `Kompan.WebGeneralTags.${item.value}` as TranslationKey,
                                                item.value as string,
                                            )} (${item.count ?? 0})`}
                                            checked={
                                                selectedItems?.find((option) =>
                                                    isSameFacetItem(option, item),
                                                ) !== undefined
                                            }
                                            onChange={(event) => {
                                                onFacetChange(event, facet, item);
                                            }}
                                        />
                                    ))}
                            </StyledFilterAccordionContent>
                        ) : null}

                        {facet.displayType === 'MULTI_RANGE' ? (
                            <StyledFilterAccordionContent key={`${facet.attribute}-${index}`}>
                                <MultiRangeFilter
                                    facet={facet}
                                    selectedItems={selectedItems as RangeItem[]}
                                    onChange={onFacetChange}
                                />
                            </StyledFilterAccordionContent>
                        ) : null}

                        {facet.displayType === 'RANGE' ? (
                            <StyledFilterAccordionContent key={`${facet.attribute}-${index}`}>
                                <RangeFilter facet={facet} />
                            </StyledFilterAccordionContent>
                        ) : null}
                    </AccordionItem>
                );
            })}
        </StyledAccordion>
    );
};
