import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledProductCategoryListContainer = styled.div();

export const StyledProductCategoryList = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[2],
    [breakpoints.sm]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
}));
